import Vue from "vue";
import VueAxios from "vue-axios";
import axios from 'axios'
import store from "../store";

axios.defaults.headers.common['Cache-Control'] = 'no-cache'
axios.defaults.headers.common['x-from'] = 'web'
// axios.defaults.headers.common['origin'] = window.location.href
const language = navigator ? (navigator.userLanguage ? navigator.userLanguage : navigator.language) : store.state.token
axios.defaults.headers.common['x-language'] = language.split('-')[0]
axios.interceptors.request.use(
  config => {
    if (store.state.token) {
      config.headers.common['x-auth'] = store.state.token
    } else if (localStorage && localStorage.getItem('token')) {
      config.headers.common['x-auth'] = localStorage.getItem('token')
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  });
axios.interceptors.response.use(function (response) {
  return response.data
}, function (error) {
  if (error && error.response && error.response.status) {
    switch (error.response.status) {
      case 401:
        store.commit('setToken', false)
        store.commit('setAccount', false)
        if (store.state.needAuth) {
          window.location.href = '/sign-in'
        }
        break
      default:
        return Promise.reject(error.response.data)
    }
  } else {
    return Promise.reject(error.response.data)
  }
})

Vue.use(VueAxios, axios);
