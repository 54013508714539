import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const SignIn = resolve => require(['../pages/sign-in'], resolve)
const Index = resolve => require(['../pages/index'], resolve)
const Test = resolve => require(['../pages/demo1'], resolve)
// const Hutu = resolve => require(['../pages/hutu'], resolve)
const Upload = resolve => require(['../pages/upload'], resolve)
const MineIndex = resolve => require(['../pages/mine/index'], resolve)
const Manage = resolve => require(['../pages/mine/manage'], resolve)
const Xr = resolve => require(['../pages/xr'], resolve)
const Agreement = resolve => require(['../pages/agreement'], resolve)
let path = [
  {
    path: '/',
    name: Index.name,
    component: Index,
    meta: {auth: true}
  },
  {
    path: '/upload',
    name: Upload.name,
    component: Upload,
    meta: {auth: true}
  },
  {
    path: '/mine/index',
    name: MineIndex.name,
    component: MineIndex,
    meta: {auth: true}
  },
  {
    path: '/mine/index/:id',
    name: MineIndex+'detail',
    component: MineIndex,
    meta: {auth: true}
  },
  {
    path: '/mine/manage',
    name: Manage.name,
    component: Manage,
    meta: {auth: true}
  },
  {
    path: '/mine/manage/:id',
    name: Manage+'detail',
    component: Manage,
    meta: {auth: true}
  },
  {
    path: '/sign-in',
    name: SignIn.name,
    component: SignIn
  },
  {
    path: '/test',
    name: Test.name,
    component: Test
  },
  // {
  //   path: '/hutu',
  //   name: Hutu.name,
  //   component: Hutu
  // },
  {
    path: '/xr',
    name: Xr.name,
    component: Xr
  },
  {
    path: '/agreement',
    name: Agreement.name,
    component: Agreement
  },
]


let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: path
})

export default router;
