import './config/release'
import './plugins'
import router from './router'
import Vue from 'vue'
import store from './store'
import App from './App.vue'
import Carousel3d from 'vue-carousel-3d';
Vue.use(Carousel3d);
store.commit('setToken', localStorage.getItem('token'))
router.beforeEach((to, from, next) => {
  if(!to.meta || !to.meta.auth){
    next();
    return;
  }
  if (to.meta && to.meta.auth && !store.state.manager) {
    if (!localStorage.getItem('token')) {
      next('/sign-in')
      return
    }
    store.dispatch('AccountInfo', to.path).then(() => {
      next()
    }).catch(() => {
      next('/sign-in')
    })
  }else{
    next()
  }
})

new Vue({
  el: '#avatar_admin',
  router,
  store,
  render: h => h(App)
});
